<template>
  <div class="page">
    <div class="main">
      <div class="maintitle">送评订单详情</div>
      <van-steps style="margin: 0 15px" :active="info.status">
        <van-step>已接收</van-step>
        <van-step>已送评</van-step>
        <van-step>评级中</van-step>
        <van-step>已付款</van-step>
        <van-step>已返还</van-step>
      </van-steps>

      <div class="list-title">订单信息</div>
      <div class="list5">
        <div class="item">
          <div class="title">订单编号</div>
          <div class="after">{{ info.order_sn }}</div>
        </div>
        <div class="item">
          <div class="title">送评表编号</div>
          <div class="after">{{ info.spb_sn }}</div>
        </div>
        <!-- <div class="item">
          <div class="title">送评服务</div>
          <div class="after">{{ info.service }}</div>
        </div>
        <div class="item">
          <div class="title">服务类型</div>
          <div class="after">{{ info.type }}</div>
        </div> -->
        <!-- <div class="item">
          <div class="title">物品数</div>
          <div class="after">{{ info.amount }}</div>
        </div> -->
        <div class="item">
          <div class="title">接收日期</div>
          <div class="after">{{ info.received_at }}</div>
        </div>
        <div class="item">
          <div class="title">送评日期</div>
          <div class="after">{{ info.songping_at }}</div>
        </div>
        <div class="item">
          <div class="title">返还日期</div>
          <div class="after">{{ info.express_at }}</div>
        </div>
      </div>

      <!-- <div class="list-title">账单信息</div>
      <div class="list5">
        <div class="item">
          <div class="title">评级费</div>
          <div class="after">￥{{ info.pingji_fee }}</div>
        </div>
        <div class="item">
          <div class="title">额外费用</div>
          <div class="after">￥{{ info.other_fee }}</div>
        </div>
        <div class="item">
          <div class="title">代理服务费</div>
          <div class="after">￥{{ info.daili_fee }}</div>
        </div>
        <div class="item">
          <div class="title">优惠金额</div>
          <div class="after">￥{{ info.youhui }}</div>
        </div>
        <div class="item">
          <div class="title">账单金额</div>
          <div class="after">￥{{ info.fee }}</div>
        </div>
        <div class="item">
          <div class="title">付款时间</div>
          <div class="after">{{ info.payed_at || '未支付' }}</div>
        </div>
      </div> -->

      <!-- <div class="list-title">鉴定信息</div>
      <div class="list5">
        <div class="item">
          <div class="title">鉴定结果</div>
          <div class="after">{{ info.pingji_result }}</div>
        </div>
        <div class="item">
          <div class="title">鉴定时间</div>
          <div class="after">{{ info.pingji_at }}</div>
        </div>
        <div class="item">
          <div class="title">鉴定说明</div>
          <div class="after">{{ info.pingji_desc }}</div>
        </div>
      </div> -->

      <div class="list-title" v-if="info.photos && info.photos.length">拍品照片</div>
      <div class="photo-list">
        <a class="item" v-for="(item, index) in info.photos" :key="index" target="_blank" :href="ADMIN + item.file_path">
          <img :src="ADMIN + item.file_path" alt="">
        </a>
      </div>

      <div class="list-title" v-if="info.pingji_photos && info.pingji_photos.length">证书照片</div>
      <div class="photo-list">
        <a class="item" v-for="(item, index) in info.pingji_photos" :key="index" target="_blank" :href="ADMIN + item.file_path">
          <img :src="ADMIN + item.file_path" alt="">
        </a>
      </div>
    </div>
    <button class="cx-btn" v-if="info.chaxun" @click="chaxun">查询评级进度</button>
  </div>
</template>

<style lang='scss' scord>
.cx-btn{
  margin: 10px 20px;
  height: 40px;
  background: #F5C837;
  border: none;
  font-size: 16px;
  font-weight: 500;
  border-radius: 3px;
}
.maintitle {
  font-size: 20px;
  margin: 20px;
}
.page {
  background: #f5f5f5;
}
.list-title {
  padding: 0 15px;
  margin-top: 20px;
  color: #666;
  margin-bottom: 5px;
}
</style>

<script>
import { Step, Steps } from "vant";
export default {
  components: {
    [Step.name]: Step,
    [Steps.name]: Steps,
  },
  data() {
    return {
      shop_id: null,
      id: null,
      sn: null,
      info: {},
    };
  },

  created() {
    this.shop_id = this.$route.params.shop_id;
    this.id = this.$route.query.id;
    this.sn = this.$route.query.sn;
    this.getItem();
  },

  mounted() {
    localStorage.setItem("backTo", this.$route.fullPath);
  },
  methods: {
    chaxun () {
      window.open(this.info.chaxun)
    },
    async getItem() {
      let url = "";
      if(this.id){
        url = "/service/info?id=" + this.id;
      }
      if(this.sn){
        url = "/service/info?sn=" + this.sn;
      }
      const r = await this.$axios.get(url);
      if (r.status == 1) {
        this.info = r.info;
      }
      console.log(r);
    },
  },
};
</script>